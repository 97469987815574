import React from 'react';
import loadable from '@loadable/component';
import {graphql} from 'gatsby';
import {Location} from '@reach/router';
import Content from '../components/Content';

const PageHeader = loadable(() => import('../components/PageHeader'));
const Layout = loadable(() => import('../components/Layout'));


// Export Template for use in CMS preview
export const UrgencePageTemplate = ({
                                          title,
                                          subtitle,
                                          featuredImage,
                                          text
                                      }) => (
    <Location>
        {() => {
            return (
                <main className="Blog">
                    <PageHeader
                        title={title}
                        subtitle={subtitle}
                        backgroundImage={featuredImage}
                    />

                    <section className="section mb-8">
                        <div className="container">
                            <h2>Que faire en cas d'urgence ?</h2>
                            <hr></hr>
                            <Content className="mt-8 text-neutral md:w-2/3 ml-auto mr-auto" source={text}/>
                        </div>
                    </section>



                </main>
            )
        }}
    </Location>
);




const UrgencePage = ({ data: { page } }) => (
    <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
    >
        <UrgencePageTemplate
            {...page}
            {...page.fields}
            {...page.frontmatter}
        />
    </Layout>
);

export default UrgencePage

export const pageQuery = graphql`
  query UrgencePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
        featuredImage
        text
      }
    }
  }
`;

